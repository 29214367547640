<template>
    <b-overlay :show="loading">
        <b-row class="match-height">
            <b-col lg="9">
                <bienvenida />
            </b-col>

            <b-col lg="3">
                <resumen-cursos
                    v-if="cursosActivos"
                    icon="BookIcon"
                    :statistic="cursosActivosTotal"
                    statistic-title="Cursos "
                    :chart-data="cursosActivos.series"
                    :chart-options="cursosActivos.chartOptions"
                />
                <!-- <horizontal-bar-chart :data-value="totalesCursosActivos"/>-->

            </b-col>
            <b-col lg="6">
                <resumen-fichas :data-value="resumenFichas" />
            </b-col>

            <b-col lg="6">
                <totales-alumnos :data-value="totalesAlumnos" />
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import ConsolidadoFicha from '@/components/dashboard/ConsolidadoFicha.vue'
import ResumenFichas from '@/components/dashboard/ResumenFichas.vue'
import TotalesAlumnos from '@/components/dashboard/TotalesAlumnos.vue'
import Bienvenida from '@/components/dashboard/Bienvenida.vue'
import GraficaEstadistica from '@/components/dashboard/GraficaEstadistica.vue'
import { kFormatter } from '@core/utils/filter'
import { $themeColors } from '@themeConfig'
import BienvenidaCorona from "@/components/dashboard/BienvenidaCorona";

const $successColorShade = '#28dac6'
export default {
    name: 'Dashboard',
    components: {
      BienvenidaCorona,
        'consolidado-ficha': ConsolidadoFicha,
        'resumen-fichas': ResumenFichas,
        'totales-alumnos': TotalesAlumnos,
        bienvenida: Bienvenida,
        'resumen-cursos': GraficaEstadistica,
    },
    data() {
        return {
            filterData: {
                num_ficha: null,
                num_orden_compra: null,
            },
            welcome: {
                name: 'Pablo Medina',
            },
            cursosActivos: {
                series: [
                    {
                        name: 'cursos',
                        data: [40, 36, 52, 38, 60, 55],
                    },
                ],
                analyticsData: {
                    subscribers: 620,
                },
            },
            dataConsolidadoFicha: [
                {
                    id: 1,
                    field: 'Nombre del curso',
                    text: '',
                    avatar: 'BookIcon',
                    avatarVariant: 'light-dark',
                    visible: true,
                },
                {
                    id: 2,
                    field: 'Número de Inscripción',
                    text: '',
                    avatar: 'FileTextIcon',
                    avatarVariant: 'light-primary',
                    visible: true,
                },
                {
                    id: 3,
                    field: 'Estado',
                    text: '',
                    avatar: 'BookmarkIcon',
                    avatarVariant: 'light-warning',
                    visible: true,
                },
                {
                    id: 4,
                    field: 'Cantidad alumnos',
                    text: '',
                    avatar: 'UsersIcon',
                    avatarVariant: 'light-info',
                    visible: true,
                },
                {
                    id: 5,
                    field: 'Aprobados',
                    text: '',
                    avatar: 'UserCheckIcon',
                    avatarVariant: 'light-success',
                    visible: true,
                },
                {
                    id: 6,
                    field: 'Reprobados',
                    text: '',
                    avatar: 'UserMinusIcon',
                    avatarVariant: 'light-danger',
                    visible: true,
                },
                {
                    id: 7,
                    field: 'Sin Nota Final',
                    text: '',
                    avatar: 'UserMinusIcon',
                    avatarVariant: 'light-danger',
                    visible: true,
                },
            ],
            totalCursosActivos: '',
            totalFichas: '',
            totalFichasActivas: '',
            totalFichasProximasCerrar: '',
            totalFichasProximasIniciar: '',
            totalAlumnos: '',
            totalAlumnosActivos: '',
            fichaResumen: [],
        }
    },
    computed: {
        loading() {
            return this.$store.state.dashboardCarozzi.loading
        },
        fichasResumen() {
            return this.$store.state.dashboardCarozzi.resumenFicha
        },
        fichasTotal() {
            this.totalFichas = this.$store.state.dashboardCarozzi.totalFichas
            return this.totalFichas
        },
        fichasActivas() {
            this.totalFichasActivas = this.$store.state.dashboardCarozzi.totalFichasActivas
            return this.totalFichasActivas
        },
        alumnosTotal() {
            this.totalAlumnos = this.$store.state.dashboardCarozzi.totalAlumnos
            return this.totalAlumnos
        },
        alumnosActivostotal() {
            this.totalAlumnosActivos = this.$store.state.dashboardCarozzi.totalAlumnosActivos
            return this.totalAlumnosActivos
        },
        fichasProximasCerrar() {
            this.totalFichasProximasCerrar = this.$store.state.dashboardCarozzi.fichasProximasCerrar
            return this.totalFichasProximasCerrar
        },
        fichasProximasIniciar() {
            this.totalFichasProximasIniciar = this.$store.state.dashboardCarozzi.fichasProximasIniciar
            return this.totalFichasProximasIniciar
        },
        resumenFichas() {
            const xciento = (this.fichasActivas > 0 && this.fichasTotal > 0) ? [((this.fichasActivas / this.fichasTotal) * 100).toFixed(0)] : [0]
            return {
                total: this.fichasTotal,
                activas: this.fichasActivas,
                activasXCiento: xciento,
                totalProximasCerrar: this.fichasProximasCerrar,
                totalProximasIniciar: this.fichasProximasIniciar,
            }
        },
        cursosActivosTotal() {
            return  this.$store.state.dashboardCarozzi.totalCursos;
        },
        totalesAlumnos() {
            const xciento = (this.alumnosActivostotal > 0 && this.alumnosTotal > 0) ? [((this.alumnosActivostotal / this.alumnosTotal) * 100).toFixed(0)] : [0]
            return {
                total: this.alumnosTotal,
                activos: this.alumnosActivostotal,
                series: xciento,
            }
        },
        consolidadoFicha() {
            const data = this.dataConsolidadoFicha
            if (this.fichasResumen) {
                data[0].text = this.fichasResumen.curso
                data[1].text = this.fichasResumen.num_ficha
                data[2].text = this.fichasResumen.estado
                data[3].text = this.fichasResumen.total_alumnos
                data[4].text = this.fichasResumen.alumnos_aprobados
                data[5].text = this.fichasResumen.alumnos_reprobados
                data[6].text = this.fichasResumen.sin_nota_final
            } else {
                this.$bvToast.toast('Ha ocurrido un error, intente más tarde', {
                    title: 'ALERTA!!',
                    variant: 'danger',
                    solid: true,
                })
            }

            return data
        },

    },
    watch: {
        cursosActivosTotal() {

          this.getTotalFichas()
        },
        totalFichas() {
            this.getTotalFichasActivas()
        },
        totalFichasActivas() {
            this.getFichasProximasCerrar()
        },
        totalFichasProximasCerrar() {
            this.getFichasProximasIniciar()
        },
        totalFichasProximasIniciar() {
            this.getTotalAlumnos()
        },
        totalAlumnos() {
            this.getTotalAlumnosActivos()
        },
        totalAlumnosActivos() {
            this.getFichaResumen()
        },
    },
    mounted() {
           this.getTotalCursos()
    },
    methods: {
        async getUserMenu() {
            return this.$store.dispatch('auth/getUserMenu')
        },
        filtrarFicha(filter) {
            this.filterData = filter
            this.getFichaResumen()
        },
        getFichaResumen() {
            this.$store.dispatch('dashboardCarozzi/getResumenFicha', this.filterData)
        },
        getConsolidadoResumen() {
            this.$store.dispatch('dashboardCarozzi/getConsolidadoResumen')
        },
        getTotalCursos() {
            this.$store.dispatch('dashboardCarozzi/getTotalCursos')
        },
        getTotalFichas() {
            this.$store.dispatch('dashboardCarozzi/getTotalFichas')
        },
        getTotalFichasActivas() {
            this.$store.dispatch('dashboardCarozzi/getTotalFichasActivas')
        },
        getFichasProximasCerrar() {
            this.$store.dispatch('dashboardCarozzi/getFichasProximasCerrar')
        },
        getFichasProximasIniciar() {
            this.$store.dispatch('dashboardCarozzi/getFichasProximasIniciar')
        },
        getTotalAlumnos() {
            this.$store.dispatch('dashboardCarozzi/getTotalAlumnos')
        },
        getTotalAlumnosActivos() {
            this.$store.dispatch('dashboardCarozzi/getTotalAlumnosActivos')
        },
        kFormatter,
    },

}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
